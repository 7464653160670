# version 0.1.1

UploadFileFtr = null
### @ngInject ###
UploadFileFtr = ($cordovaFile, $http, AlertService, ConfigCnst, gettextCatalog) ->
    data =
        p1: ''
        p2: ''
    server  = encodeURI ConfigCnst.restUrl+'/images'
    options = {}

#    if ionic.Platform.isWebView()
#        options = new FileUploadOptions()
#        options.chunkedMode = false
#        options.mimeType    = 'image/jpeg'
#        options.params      =
#            type: 'mobile'
#            description: 'Uploaded from my phone'

    service =
        response: ''

        upload: (i, filePath, onProgressUpdate, onComplete) ->
            sm = @

            $cordovaFile.uploadFile server, filePath, options
            .then (res) ->
                # res = { bytesSent | objectId | response | responseCode }
                sm.response = JSON.parse res.response
                onComplete i
                AlertService.showError res.message, 'Fail upload photo' if not res.status and res.message
                return
            , (err) ->
                if err.code and err.source
                    error = gettextCatalog.getString "[#{err.code}] Fail upload file #{err.source} to server"
                else
                    error = gettextCatalog.getString 'Upload photo request failed'
                AlertService.showError error
                return
            , (progress) ->
#                p =
#                    bubbles: false
#                    cancelBubble: false
#                    cancelable: false
#                    lengthComputable: true
#                    loaded: 214684
#                    total: 214684
#                    target: null
                percent = (progress.loaded / progress.total) * 100
                onProgressUpdate i, percent, progress
                return
            return

        remove: (id) ->
            sm = @

            $http.delete server+'/'+id
            .success (res) ->
                sm.response = res
                return
            return

        getData: -> data
    service


angular.module 'app'
.factory 'UploadFileFtr', UploadFileFtr
